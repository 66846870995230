<template>
  <div>
    <div class="md-layout-item">
      <chart-card
        v-if="loaded"
        header-animation="false"
        :chart-data="multipleBarsChart.data"
        :chart-options="multipleBarsChart.options"
        chart-type="Bar"
        header-icon
        chart-inside-content
        no-footer
        background-color="green"
      >
        <template slot="chartInsideHeader">
          <h4 class="title">
            Expected Income per Month
          </h4>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-size-30 md-small-size-100">
      <md-card class="">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">
            Generate Report
          </h4>
        </md-card-header>

        <md-card-content>
          <form-date
            v-model="filters.from"
            name="from"
            label="From..."
          />
          <form-date
            v-model="filters.to"
            name="to"
            label="To..."
          />
          <AgentsSelect
            v-if="userIsAgentsManager || !userIsAgent"
            v-model="filters.agent_id"
            :agent-groups="agentGroupsManagerIds"
          />
          <md-button
            class="md-info md-block"
            @click="downloadReport"
          >
            Download
          </md-button>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ChartCard } from '@/components';
import { FormDate } from '@/components/Inputs';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';
import {
  AgentsSelect,
} from '@/components/Inputs/selects';

export default {
  components: {
    ChartCard,
    FormDate,
    AgentsSelect,
  },
  props: [],
  data() {
    return {
      filters: {
        from: null,
        to: null,
        agent_id: null,
      },
      multipleBarsChart: {
        data: {
          labels: [],
          series: [
            [],
          ],
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
          height: '300px',
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      loaded: false,
      loading: false,
    };
  },
  mounted() {
    this.getReportData();
  },
  methods: {
    getReportData() {
      this.multipleBarsChart.data.labels = [];
      this.multipleBarsChart.data.series = [[]];

      this.request(this.$API.INCOME_PROYECTION, 'get', { ...this.filters }, ({ data }) => {
        for (const m of [...Array(12).keys()]) {
          const month = moment().add(m, 'months');
          const monthData = data.find((x) => x.month === parseInt(month.format('M'), 10));

          this.multipleBarsChart.data.labels.push(month.format('MMM'));
          this.multipleBarsChart.data.series[0].push(monthData ? monthData.qty : 0);
        }
      }, () => {
        this.loaded = true;
      });
    },
    downloadReport() {
      this.loading = true;
      this.request(this.$API.INCOME_PROYECTION, 'download', {
        ...this.filters,
        download: true,
      }, ({ data }) => {
        const filename = `Income_Projection_report_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
</style>
